<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="justify-content-center login_page">
      <CCol md="8">

      </CCol>
      <CCol md="4">
        <CCardGroup class="form_login_page">
          <CCard class="">
            <CCardBody>

              <h1>Login</h1>
              <p class="text-muted text-center">Sign In to your account</p>
              <div class="">
                <label>Email</label>
                <input type="text" placeholder=" " v-model="loginForm.username" class="formControl login_input" />
                <span v-if="$v.loginForm.username.$error && loginValidate"
                  style="color: red; float: left; margin-left: 20px" class="error">Email must be valid</span>

              </div>
              <div class="">
                <label>Password</label>
                <input :type="inputType" placeholder=" " class="formControl login_input" v-model="loginForm.password"
                  @keyup.enter="handleLogin" />

                <span v-if="$v.loginForm.password.$error && loginValidate"
                  style="color: red; float: left; margin-left: 20px" class="error">Password required</span>
              </div>

              <CRow>
                <button class="buttonload btn btn--blue w100" style="pointer-events: none" v-if="isSubmittingLogin">
                  Submitting ...
                </button>



                <button title="Login" v-if="!isSubmittingLogin" @click="handleLogin"
                  class="login_submit_btn">Login</button>

              </CRow>

            </CCardBody>
          </CCard>
          <CCard color="primary" text-color="white" class="text-center py-5 d-md-down-none" style="width:44%"
            body-wrapper>

          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { mapGetters } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "Login",
  computed: {
    ...mapGetters(["role"])
  },
  data() {
    return {
      loginForm: {
        username: "", //admin@gmail.com(admin)
        password: "", //123456
      },
      loading: false,
      loginValidate: false,
      isSubmittingLogin: false
    };
  },
  computed: {
    inputType() {
      return this.showPassword ? 'text' : 'password';
    }
  },
  methods: {
    handleLogin() {
      this.loginValidate = true;
      let redirectTo = '/admin/users';
      console.log("redirectTo", redirectTo);
      this.$v.loginForm.$touch();
      if (this.$v.loginForm.$error) {
        return;
      }
      this.loginValidate = false;
      this.loading = true;
      this.isSubmittingLogin = true;
      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          this.$notify({
            group: "notify",
            type: "success",
            text: "Login successfully",
          });
          if (redirectTo) {
            this.$router.push({ path: redirectTo });
          }
          this.openModal();
          this.loading = false;
          this.isSubmittingLogin = false;
        })
        .catch((err) => {
          this.loading = false;

          err.response.data.error &&
            this.$notify({
              group: "notify",
              type: "error",
              text: err.response.data.error,
            });
          if (err.response.data.data) {
            this.$notify({
              group: "notify",
              type: "error",
              text: err.response.data.data.email[0],
            });
          }
          this.isSubmittingLogin = false;
        });
    }
  },
  validations: {
    loginForm: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
    userEmail: {
      required,
      email,
    },
  },
};
</script>
<style>
body {
  margin: 0px !important;
  background-color: #000;
}

.login_page {
  background: #fff;
  height: 98.5vh;
  display: flex;
  width: 100%;
}

.login_page .col-md-8 {
  width: 50%;
  background-image: url("http://platform.extraslice.com/img/hero-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.login_page .col-md-4 {
  width: 50%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}

.login_page h1 {
  font-size: 43px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;

}

.form_login_page {
  width: 80%;
}

.form_login_page input {
  height: 46px;
  width: 92.5%;
  margin-bottom: 30px;
  border: 1px solid #797676;
  border-radius: 3px;
  padding: 2px 20px;
  margin-top: 8px;
}

.form_login_page label {
  font-size: 16px;
}

.login_submit_btn {
  background-color: #2996b8;
  border: 0;
  color: #fff;
  width: 100%;
  height: 46px;
  cursor: pointer;
}

.form_login_page input:focus-visible {
  outline: 0;
}

.form_login_page p {
  text-align: center;
}
</style>